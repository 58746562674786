import React from 'react'
import { useState, useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'
import {AiOutlineDelete, AiOutlineEdit} from "react-icons/ai"

function TableForm({description, setDescription, quantity, setQuantity, price, setPrice, amount, setAmount, list, setList, total, setTotal}) {
  const [isEditing, setIsEditing] = useState(false)  
  
  const handleSubmit = (e) => {
        e.preventDefault()

        if (!description || !quantity || !price) {
          alert("Please fill in all inputs")
        } else {
          const newItems = {
            id: uuidv4(),
            description: description,
            quantity: quantity,
            price: price,
            amount: amount
        }

        setTotal(total+amount)

        setDescription("")
        setQuantity("")
        setPrice("")
        setAmount("")
        setList([...list, newItems])
        setIsEditing(false)
        console.log(list)
        }

        
    }
  
    useEffect(() => {
    const calculateAmount = (amount) => {
        setAmount(quantity*price)
      }

      calculateAmount(amount)
  }, [amount, price, quantity, setAmount])

  // let rows = document.querySelectorAll(".amount")
  // let sum = 0

  // for(let i = 0; i < rows.clientHeight; i++) {
  //   if (rows[i].className === "amount") {
  //     sum += isNaN(rows[i].innerHTML) ? 0 : parseInt(rows[i].innerHTML)
  //     setTotal(sum)
  //   }
  // }

  const deleteRow = (id) => {
    setList(list.filter((row) => row.id !== id))
    const currentAmount = list.find((row) => row.id ===id)
    setTotal(total - currentAmount.amount)
  }

  const editRow = (id) => {
    const edittingRow = list.find((row) => row.id === id)
    const prevAmount = edittingRow.amount
    setIsEditing(true)
    setList(list.filter((row) => row.id !== id))
    setDescription(edittingRow.description)
    setQuantity(edittingRow.quantity)
    setPrice(edittingRow.price)
    setTotal(total - prevAmount)
    console.log(prevAmount)
  }
    
  
  return (
    <>
    <form onSubmit={handleSubmit}>
        <div className='flex flex-col md:mt-16'>
        <label htmlFor='description'>Item description</label>
        <input type='text' name='description' id='description' placeholder='Item description' value={description} onChange={(e) => setDescription(e.target.value)}></input>
    </div>

    <div className='md:grid grid-cols-3 gap-10'>

    <div className='flex flex-col'>
        <label htmlFor='quantity'>Quantity</label>
        <input type='text' name='quantity' id='quantity' placeholder='Quantity' value={quantity} onChange={(e) => setQuantity(e.target.value)}></input>
    </div>

    <div className='flex flex-col'>
        <label htmlFor='price'>Price</label>
        <input type='text' name='price' id='price' placeholder='Price' value={price} onChange={(e) => setPrice(e.target.value)}></input>
    </div>

    <div className='flex flex-col'>
        <label htmlFor='amount'>Amount</label>
        <p>{amount}</p>
    </div>
    </div>

    <button type='submit' className="bg-blue-500 text-white font-bold mb-5 py-2 px-8 rounded shadow border-2
              border-blue-500 hover:bg-transparent hover:text-blue-500 transition-all duration-150">{isEditing ? "Edit Row Item" : "Add Table Item"}</button>
    </form>

    <table width="100%" className='mb-10'>
        <thead>
          <tr className="bg-gray-100 p-1">
            <td className="font-bold">Description</td>
            <td className="font-bold">Quantity</td>
            <td className="font-bold">Price (usd$)</td>
            <td className="font-bold">Amount (usd$)</td>
          </tr>
        </thead>
        {list.map(({ id, description, quantity, price, amount }) => (
          <React.Fragment key={id}>
            <tbody>
              <tr>
                <td>{description}</td>
                <td>{quantity}</td>
                <td>{price}</td>
                <td className='amount'>{amount}</td>
                <td><button onClick={() => deleteRow(id)}><AiOutlineDelete className='text-red-500 text-xl'/></button></td>
                <td>
                  <button onClick={() => editRow(id)}><AiOutlineEdit className='text-green-500 text-xl'/></button>
                </td>
              </tr>
            </tbody>
          </React.Fragment>
        ))}
      </table>

      <div>
        <h2 className='flex items-end justify-end text-gray-800 text-4xl font-bold'>Total: USD$ {total.toLocaleString()}</h2>
      </div>

    </>
  )
}

export default TableForm