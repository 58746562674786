import React from 'react'

function MainDetails({name, address}) {
  return (
    <section className="flex flex-col items-end justify-end">
          <h2 className="font-bold text-xl mb-1 uppercase md:text-4xl">{name}</h2>
          <p>{address}</p>
    </section>
  )
}

export default MainDetails