import { useState, useRef } from "react";
import ClientDetails from "./components/ClientDetails";
import Dates from "./components/Dates";
import Footer from "./components/Footer";
import Header from "./components/Header";
import MainDetails from "./components/MainDetails";
import Notes from "./components/Notes";
import Table from "./components/Table";
import TableForm from "./components/TableForm";
import ReactToPrint from "react-to-print";

function App() {
  const [showInvoice, setShowInvoice] = useState(false)
  const [address, setAddress] = useState("")
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [bankName, setBankName] = useState("")
  const [bankAccount, setBankAccount] = useState("")
  const [website, setWebsite] = useState("")
  const [clientName, setClientName] = useState("")
  const [clientAddress, setClientAddress] = useState("")
  const [invoiceNumber, setInvoiceNumber] = useState("")
  const [invoiceDate, setInvoiceDate] = useState("")
  const [dueDate, setDueDate] = useState("")
  const [notes, setNotes] = useState("")
  const [description, setDescription] = useState("")
  const [quantity, setQuantity] = useState("")
  const [price, setPrice] = useState("")
  const [amount, setAmount] = useState("")
  const [list, setList] = useState([])
  const [total, setTotal] = useState(0)

  const componentRef = useRef()
  
  const handlePrint = () => {
    window.print()
  }

  return (
    <>
      <main className="m-5 p-5 md:max-w-xl lg:max-w-2xl xl:max-w-4xl md:mx-auto bg-white rounded shadow">
        {showInvoice ? 
        <>
        <div>
          <ReactToPrint trigger={() => <button className="flex justify-end float-right bg-blue-500 text-white font-bold py-2 px-8 rounded shadow border-2
              border-blue-500 hover:bg-transparent hover:text-blue-500 transition-all duration-150 mr-5 mt-2">Print / Download</button>} content={() => componentRef.current}/>
        </div>
        
        <div ref={componentRef} className="p-5">
          <Header handlePrint={handlePrint}></Header>
          <MainDetails name={name} address={address}></MainDetails>
          <ClientDetails clientName={clientName} clientAddress={clientAddress}></ClientDetails>
          <Dates invoiceNumber={invoiceNumber} invoiceDate={invoiceDate} dueDate={dueDate}></Dates>
          <Table description={description} quantity={quantity} price={price} amount={amount} list={list} setList={setList} total={total} setTotal={setTotal}></Table>
          <Notes notes={notes}></Notes>
          <Footer name={name} address={address} website={website} email={email} phone={phone} bankAccount={bankAccount} bankName={bankName}></Footer>
        </div>
        
        <button onClick={() => setShowInvoice(false)} className="bg-blue-500 text-white font-bold mt-5 py-2 px-8 rounded shadow border-2
              border-blue-500 hover:bg-transparent hover:text-blue-500 transition-all duration-150">Back</button> 
        </>
        : (
          <>
            <div><h1 className="font-bold text-4xl mb-10">Invoice Sender for LFonds</h1></div>

            <div className="flex flex-col justify-center">

            <article className="md:grid grid-cols-2 gap-10">
            <div className="flex flex-col">
              <label htmlFor="name">Full name</label>
              <input type="text" name = "text" id="name" placeholder="Enter your name" value={name} onChange={(e) => setName(e.target.value)}/>
            </div>

            <div className="flex flex-col">
              <label htmlFor="address">Address</label>
              <input type="text" name = "address" id="address" placeholder="Enter your address" value={address} onChange={(e) => setAddress(e.target.value)}/>
            </div>
            </article>  

            <article className="md:grid grid-cols-3 gap-10">
            <div className="flex flex-col">
              <label htmlFor="email">Email</label>
              <input type="text" name = "email" id="email" placeholder="Enter your email" value={email} onChange={(e) => setEmail(e.target.value)}/>
            </div>

            <div className="flex flex-col">
              <label htmlFor="website">Website</label>
              <input type="url" name = "website" id="website" placeholder="Enter your website" value={website} onChange={(e) => setWebsite(e.target.value)}/>
            </div>

            <div className="flex flex-col">
              <label htmlFor="phone">Phone Number</label>
              <input type="text" name = "phone" id="phone" placeholder="Enter your phone" value={phone} onChange={(e) => setPhone(e.target.value)}/>
            </div>
            </article>

            <article className="md:grid grid-cols-2 gap-10">
            <div className="flex flex-col">
              <label htmlFor="bankName">Bank Name</label>
              <input type="text" name = "bankName" id="bankName" placeholder="Enter your bank name" value={bankName} onChange={(e) => setBankName(e.target.value)}/>
            </div>

            <div className="flex flex-col">
              <label htmlFor="bankAccount">Bank Account Number</label>
              <input type="text" name = "bankAccount" id="bankAccount" placeholder="Enter your bank account number" value={bankAccount} onChange={(e) => setBankAccount(e.target.value)}/>
            </div>
            </article>

            <article className="md:grid grid-cols-2 gap-10  md:mt-16">
            <div className="flex flex-col">
              <label htmlFor="clientName">Client's Name</label>
              <input type="text" name = "clientName" id="clientName" placeholder="Enter your client's name" value={clientName} onChange={(e) => setClientName(e.target.value)}/>

            </div>
              
            <div className="flex flex-col">
              <label htmlFor="clientAddress">Client's Address</label>
              <input type="text" name = "clientAddress" id="clientAddress" placeholder="Enter your client's address" value={clientAddress} onChange={(e) => setClientAddress(e.target.value)}/>
            </div>
            </article>

            <article className="md:grid grid-cols-3 gap-10">
            <div className="flex flex-col">
              <label htmlFor="invoiceNumber">Invoice Number</label>
              <input type="text" name = "invoiceNumber" id="invoiceNumber" placeholder="Enter your invoice number" value={invoiceNumber} onChange={(e) => setInvoiceNumber(e.target.value)}/>
            </div>

            <div className="flex flex-col">
              <label htmlFor="invoiceDate">Invoice Date</label>
              <input type="date" name = "invoiceDate" id="invoiceDate" placeholder="Enter your invoice date" value={invoiceDate} onChange={(e) => setInvoiceDate(e.target.value)}/>
            </div>
              
            <div className="flex flex-col">
              <label htmlFor="dueDate">Due Date</label>
              <input type="date" name = "dueDate" id="dueDate" placeholder="Enter the due date" value={dueDate} onChange={(e) => setDueDate(e.target.value)}/>
            </div>
            </article>

            <article>
              <TableForm description={description} setDescription={setDescription} quantity={quantity} setQuantity={setQuantity} price={price} setPrice={setPrice} amount={amount} setAmount={setAmount} setList={setList} list={list} total={total} setTotal={setTotal}/>
            </article>

              <label htmlFor="notes">Notes</label>
              <textarea name = "notes" id="notes" cols="30" rows="10" placeholder="Enter notes" value={notes} onChange={(e) => setNotes(e.target.value)}/>

              <button onClick={() => setShowInvoice(true)} className="bg-blue-500 text-white font-bold py-2 px-8 rounded shadow border-2
              border-blue-500 hover:bg-transparent hover:text-blue-500 transition-all duration-150">Preview Invoice</button>
            </div>
          </>
          
        )}
        
      </main>
    </>
  );
}

export default App;
